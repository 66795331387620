import { Box } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import AddRoundedIcon from "@material-ui/icons/AddRounded";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import React, { FC, isValidElement, useCallback, useEffect, useMemo, useRef } from "react";
import { useCommandBarContext } from "../../context/CommandBarContext";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { useShortcut } from "../../hooks/useShortcut";
import { smartClickAwayEventIsHandled } from "../SmartClickAwayListener";
import { Tooltip } from "../Tooltip";

const useStyles = makeStyles((theme) => ({
  paperPopper: {
    background: theme.colors.white,
    position: "relative",
    [theme.breakpoints.up("sm")]: {
      borderRadius: theme.shape.borderRadius * 3,
      width: 385,
    },
    [theme.breakpoints.down("xs")]: {
      paddingTop: theme.spacing(3),
    },
  },
  newTaskBtn: {
    margin: "0 0 0 auto",
    minWidth: 0,
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(1),
    },
    [theme.breakpoints.up("md")]: {
      margin: 0,
    },
  },
  keyboardKey: {
    backgroundColor: theme.palette.grey[700],
    borderRadius: 4,
    marginLeft: theme.spacing(1.25),
    padding: theme.spacing(0, 0.5),
  },
  mobileClose: {
    display: "none",
    [theme.breakpoints.down("xs")]: {
      display: "block",
      position: "absolute",
      right: 16,
      top: 16,
    },
  },
}));

export const GlobalAddPopover: FC = ({ children }) => {
  const classes = useStyles();
  const theme = useTheme();
  const small = useMediaQuery(theme.breakpoints.down("xs"));

  const {
    state: { CommandBar },
  } = useCommandBarContext();

  const [savedData, setSavedData] = useLocalStorage("micro.task", null);
  const [, setOverideOpenMap] = useLocalStorage<{ [key: string]: boolean }>("micro.task.section", {});

  const [open, setOpen] = React.useState<boolean>(false);

  const buttonRef = useRef<HTMLAnchorElement>();

  useShortcut("escape", () => setOpen(false), []);

  useEffect(() => {
    CommandBar?.addCallback("openQuickTask", (args, context) => setOpen(true));
  }, [CommandBar]);

  const handleToggle = useCallback(() => {
    setOpen((prev) => !prev);

    if (open) {
      // FIXME (IW): Without the timeout, the form will trigger "onChange"
      // before closing the dialog, resetting localstorage w/ last value.
      // This is 1000% hack.
      setTimeout(() => setSavedData(null), 400);
      setOverideOpenMap(null);
    }
  }, [open, setOverideOpenMap, setSavedData]);

  const handleClickAway = useCallback(
    (e: React.MouseEvent<Document>) => {
      if (smartClickAwayEventIsHandled(e)) return;
      if (!e.defaultPrevented) setOpen(false);
    },
    [setOpen]
  );

  const childrenWithClosePopover = useMemo(
    () =>
      React.Children.map(children, (child) =>
        isValidElement(child) ? React.cloneElement(child, { closePopover: handleClickAway }) : child
      ),
    [children, handleClickAway]
  );

  return (
    <>
      <Tooltip
        key="new-task-global-tip"
        title={
          !!open || small ? (
            ""
          ) : (
            <div>
              Create new task
              <span className={classes.keyboardKey}>C</span>
            </div>
          )
        }
        placement="bottom"
      >
        <Button
          className={classes.newTaskBtn}
          aria-haspopup="true"
          key="new-task-global"
          ref={buttonRef as any}
          startIcon={!!open || small ? "" : <AddRoundedIcon fontSize="small" />}
          variant="text"
          onClick={handleToggle}
        >
          {!open ? (
            small ? (
              <AddRoundedIcon fontSize="small" />
            ) : !!savedData ? (
              "Resume draft"
            ) : (
              "New task"
            )
          ) : (
            <CloseRoundedIcon fontSize="small" />
          )}
        </Button>
      </Tooltip>

      <Popper
        open={!!open}
        anchorEl={buttonRef.current}
        placement="bottom-end"
        transition
        style={{
          width: small ? "100vw" : "auto",
          zIndex: 10,
        }}
        modifiers={{
          offset: {
            enabled: true,
            offset: 0,
          },
          preventOverflow: {
            padding: 0,
          },
        }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: small ? "center top" : "right top",
            }}
          >
            <Paper className={classes.paperPopper} elevation={8}>
              <ClickAwayListener onClickAway={handleClickAway}>
                {/*  Provide immediate children the ability to close the popover */}
                <Box>{childrenWithClosePopover}</Box>
              </ClickAwayListener>

              <IconButton size="small" color="inherit" onClick={handleToggle} className={classes.mobileClose}>
                <CloseRoundedIcon />
              </IconButton>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};
